import {useLocation, useNavigate} from 'react-router-dom';
import React, { useRef, useEffect, useState } from 'react';
import { Box, Button } from "@mui/material";
import mapboxgl from 'mapbox-gl';
import GalleryPopup from "./GalleryPopup";
import urljoin from "url-join";
import apiConfig from "../apiConfig";
import fetchWithAuth from "./fetchWithAuth";
import convertImageIDToURL from "../utils"

mapboxgl.accessToken = 'pk.eyJ1IjoiYWxleGZlcmVuZXRzIiwiYSI6ImNsdWQyOTBoeTBrMDYyanBrbG81aWhwbTgifQ.9qP1_MSv9Pv2CxTT88XC-w';

function Map() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(state?.longitude || parseFloat(localStorage.getItem('mapLongitude')) || 4.95);
    const [lat, setLat] = useState(state?.latitude || parseFloat(localStorage.getItem('mapLatitude')) || 52.4);
    const [zoom, setZoom] = useState(state?.zoom || parseFloat(localStorage.getItem('mapZoom')) || 11);
    const [groupBy, setGroupBy] = useState(state?.groupBy || localStorage.getItem('mapGroupBy') || 'cities');
    const [activeGallery, setActiveGallery] = useState(null);

    useEffect(() => {
        if (map.current) return;
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [lng, lat],
            zoom: zoom
        });

        map.current.on('move', () => {
            const longitude = map.current.getCenter().lng.toFixed(4);
            setLng(longitude);
            localStorage.setItem('mapLongitude', longitude);
            const latitude = map.current.getCenter().lat.toFixed(4);
            setLat(latitude);
            localStorage.setItem('mapLatitude', latitude);
            const z = map.current.getZoom().toFixed(2);
            setZoom(z);
            localStorage.setItem('mapZoom', z);
        });

        map.current.on('load', () => {
            fetchWithAuth(navigate, urljoin(apiConfig.baseUrl, 'GetMap'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    account: 'family',
                    filter: {
                        groupLocationsBy: groupBy
                    }
                }),
            })
                .then(response => response.json())
                .then(data => {
                    var countriesCodes = [];
                    data.locations.forEach(loc => {
                        countriesCodes.push(loc.id);
                        const markerElement = document.createElement('div');
                        markerElement.className = 'marker';
                        const img = new Image();
                        img.src = convertImageIDToURL(loc.favouriteImageID || loc.trips[0].imageIDs[0], "medium");
                        img.onload = () => {
                            markerElement.style.backgroundImage = `url(${img.src})`;
                        };
                        img.onerror = () => {
                            markerElement.style.backgroundImage = `url(${process.env.PUBLIC_URL}/smile.png)`;
                        };
                        markerElement.style.width = `100px`;
                        markerElement.style.height = `100px`;
                        markerElement.style.backgroundSize = 'cover';
                        markerElement.style.borderRadius = '50%';
                        markerElement.addEventListener('click', () => setActiveGallery({ location: loc }));

                        new mapboxgl.Marker(markerElement)
                            .setLngLat([loc.longitude, loc.latitude])
                            .addTo(map.current);
                    });
                    return countriesCodes;
                }).then((countriesCodes) => {
                    if (groupBy !== "countries") {
                        return;
                    }

                    map.current.addSource("countries-no-simplification", {
                        type: "vector",
                        url: "mapbox://mapbox.country-boundaries-v1"
                    });
                    map.current.addLayer({
                        id: "country-boundaries",
                        type: "fill",
                        source: "countries-no-simplification",
                        "source-layer": "country_boundaries",
                        paint: {
                            "fill-color": "green",
                            "fill-opacity": 0.3
                        }
                    });
                    map.current.setFilter('country-boundaries', [
                        "in",
                        "iso_3166_1",
                        ...countriesCodes
                    ]);
                })
                .catch(error => console.log('Failed to get map:', error));
        });

    }, [lng, lat, zoom, navigate, groupBy]);

    const handleGroupByChange = (newGroupBy) => {
        setGroupBy(newGroupBy);
        localStorage.setItem('mapGroupBy', newGroupBy);
        window.history.replaceState({}, '');
        window.location.reload();
    };

    return (
        <div>
            <div ref={mapContainer} style={{height: '84vh', width: '100%'}}/>
            <Box display="flex" justifyContent="center" mt={1} sx={{position: "relative"}}>
                {['cities', 'countries'].map(group => (
                    <Button
                        key={group}
                        variant={groupBy === group ? "contained" : "outlined"}
                        onClick={() => handleGroupByChange(group)}
                        sx={{ mx: 1 }}
                    >
                        {group}
                    </Button>
                ))}
            </Box>
            {activeGallery && (
                <div style={galleryPopupBackgroundStyle} onClick={() => setActiveGallery(null)}>
                    <div style={galleryPopupStyle} onClick={e => e.stopPropagation()}>
                        <GalleryPopup location={activeGallery.location}/>
                    </div>
                </div>
            )}
            <div className="sidebar">
                Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
            </div>
        </div>
    );
}

const galleryPopupBackgroundStyle = {
    display: 'flex',
    overflowX: 'scroll',
    gap: '10px',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000
};

const galleryPopupStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    zIndex: 1001,
    maxHeight: '80%',
    maxWidth: '95gi%',
    minWidth: '50%',
    overflowY: 'auto',
};


export default Map;

