import React, {useCallback, useEffect, useState, createRef } from 'react';
import urljoin from "url-join";
import apiConfig from "../apiConfig";
import fetchWithAuth from "./fetchWithAuth";
import { Box, Button } from "@mui/material";
import useImageGallery from './useImageGallery';
import ImageModal from "./ImageModal";
import TripsList from "./TripsList";
import CircularProgress from "@mui/material/CircularProgress";
import {useLocation, useNavigate} from "react-router-dom";

const Gallery = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const scrollToTripId = state?.scrollToTripId || null;

    const [trips, setTrips] = useState([]);
    const [locations, setLocations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [tripRefs, setTripRefs] = useState([]);

    const { openImageFullScreen, images, selectedImage, selectedImageIndex, handleNextImage, handlePreviousImage, handleCloseModal, imageSize, handleSizeChange } = useImageGallery();

    const fetchTrips = useCallback(async () => {
        try {
            const tripsResponse = await fetchWithAuth(navigate, urljoin(apiConfig.baseUrl, 'GetTrips'), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ account: 'family' }),
            });
            const tripsData = await tripsResponse.json();
            setTrips(tripsData.trips);

            const refs = {};
            tripsData.trips.forEach(trip => {
                refs[trip.id] = createRef();
            });
            setTripRefs(refs);

            const locationsResponse = await fetchWithAuth(navigate, urljoin(apiConfig.baseUrl, 'GetLocations'));
            const locationsData = await locationsResponse.json();
            setLocations(locationsData.locations);

            setIsLoading(false);
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setIsLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        fetchTrips();
    }, [fetchTrips]);

    return (
        <div>
            <Box display="flex" justifyContent="center" my={2}>
                {['small', 'medium', 'large'].map(size => (
                    <Button
                        key={size}
                        variant={imageSize === size ? "contained" : "outlined"}
                        onClick={() => handleSizeChange(size)}
                        sx={{ mx: 1 }}
                    >
                        {size}
                    </Button>
                ))}
            </Box>
            <Box sx={{ margin: 2 }}>
                {isLoading ? <CircularProgress /> : (
                    <TripsList
                        trips={trips}
                        openImageFullScreen={openImageFullScreen}
                        locations={locations}
                        ref={tripRefs}
                        imageSize={imageSize}
                        scrollToTripId={scrollToTripId}
                    />
                )}
            </Box>
            <ImageModal
                selectedImage={selectedImage}
                handleCloseModal={handleCloseModal}
                handleNextImage={handleNextImage}
                handlePreviousImage={handlePreviousImage}
                index={selectedImageIndex}
                total={images.length}
            />
        </div>
    );
};

export default Gallery;
