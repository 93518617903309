import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from './App';
import Map from './components/Map';
import Gallery from './components/Gallery';
import Upload from './components/Upload';
import Login from './components/Login'
import './index.css'
import EditTrip from "./components/EditTrip";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
        <Route path="/" element={<App />}>
            <Route index element={< Map />} />
            <Route path="map" element={< Map />}></Route>
            <Route path="gallery" element={< Gallery />}></Route>
            <Route path="upload" element={< Upload />}></Route>
            <Route path="editTrip/:tripId" element={< EditTrip />}></Route>
            <Route path="login" element={< Login />}></Route>
        </Route>
    </Routes>
  </BrowserRouter>
);