import React from 'react';
import { Outlet } from "react-router-dom";
import Navbar from './components/Navbar';
import PageLayout from "./components/PageLayout";
import NavbarLayout from "./components/NavbarLayout";
import "./App.css"

function App() {
  return (
    <div>
        <NavbarLayout>
            <Navbar />
        </NavbarLayout>
        <PageLayout>
            <Outlet />
        </PageLayout>
    </div>
  )
}

export default App;
