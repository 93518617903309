import React from 'react';
import { Modal, Box } from "@mui/material";
import ImageViewer from "./ImageViewer";

const ImageModal = ({ selectedImage, handleCloseModal, handleNextImage, handlePreviousImage, index, total }) => {
    return (
        <Modal
            open={!!selectedImage}
            onClose={handleCloseModal}
            aria-labelledby="image-modal-title"
            aria-describedby="image-modal-description"
        >
            <Box tabIndex={0} style={{ outline: 'none' }}>
                {selectedImage ? (
                    <ImageViewer
                        imageId={selectedImage}
                        onClose={handleCloseModal}
                        onNext={handleNextImage}
                        onPrev={handlePreviousImage}
                        index={index}
                        total={total}
                    />
                ) : <div style={{ display: 'none' }}/>}
            </Box>
        </Modal>
    );
};

export default ImageModal;
