import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import _ from 'lodash';

function LocationAutocomplete({ onLocationSelected }) {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!window.google) {
      return;
    }
  }, []);

  const fetchPredictions = _.debounce((input) => {
    if (!input) {
      setOptions([]);
      return;
    }

    const autocompleteService = new window.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions({ input }, (predictions, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
        setOptions(predictions);
      } else {
        setOptions([]);
      }
    });
  }, 300);

  useEffect(() => {
    fetchPredictions(inputValue);
  }, [inputValue, fetchPredictions]);

  return (
      <Autocomplete
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(event, newValue) => {
            if (!newValue) {
              onLocationSelected(null);
              return;
            }
            const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
            placesService.getDetails({ placeId: newValue.place_id }, (result, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                onLocationSelected(result);
              }
            });
          }}
          options={options}
          getOptionLabel={(option) => option.description}
          isOptionEqualToValue={(option, value) => option.place_id === value.place_id}
          renderInput={(params) => (
              <TextField
                  {...params}
                  label="Enter location"
                  variant="outlined"
                  fullWidth
              />
          )}
      />

  );
}

export default LocationAutocomplete;
