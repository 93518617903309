let lastUpdateTimestamp = Date.now();

export const getLastUpdateTimestamp = () => lastUpdateTimestamp;

export const updateLastUpdateTimestamp = () => {
    lastUpdateTimestamp = Date.now();
};

export const participantsColorMap = {
    mom: { label: "К", color: "error", order: 1 },
    dad: { label: "Л", color: "primary", order: 2 },
    sasha: { label: "С", color: "success", order: 3 },
    tima: { label: "Т", color: "warning", order: 4 },
    masha: { label: "М", color: "info", order: 5 },
    alisa: { label: "А", color: "secondary", order: 6 }
};
