import React from 'react';
import { Typography, Box, IconButton, Modal } from '@mui/material';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { useSwipeable } from 'react-swipeable';
import convertImageIDToURL from "../utils";
import urljoin from "url-join";
import apiConfig from "../apiConfig";
import fetchWithAuth from "./fetchWithAuth";
import { updateLastUpdateTimestamp } from '../globalState';
import {useNavigate} from "react-router-dom";

const ImageViewer = React.forwardRef(({ imageId, onClose, onNext, onPrev, index, total }, ref) => {
    const navigate = useNavigate();
    const handlers = useSwipeable({
        onSwipedLeft: onNext,
        onSwipedRight: onPrev,
        preventDefaultTouchmoveEvent: true,
        trackTouch: true,
        trackMouse: false
    });

    const rotateImage = async (counterClockwise) => {
        try {
            await fetchWithAuth(navigate, urljoin(apiConfig.baseUrl, 'RotateImage'), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ account: 'family', imageID: imageId, counterClockwise })
            });
            updateLastUpdateTimestamp();
            window.location.reload();
        } catch (error) {
            console.error('Failed to rotate image:', error);
        }
    };

    return (
        <div ref={ref}>
            <Modal
                open={true}
                onClose={onClose}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box sx={boxStyle} {...handlers}>
                    <img
                        src={convertImageIDToURL(imageId)}
                        onError={(e) => e.target.src = `${process.env.PUBLIC_URL}/smile.png`}
                        alt="Full screen"
                        style={imageStyle}
                    />
                    {total > 1 && (
                        <IconButton onClick={onPrev} sx={{...swipeButtonStyle, left: 16}}>
                            <ArrowLeft/>
                        </IconButton>
                    )}
                    {total > 1 && (
                        <IconButton onClick={onNext} sx={{...swipeButtonStyle, right: 16}}>
                            <ArrowRight/>
                        </IconButton>
                    )}

                    <IconButton onClick={() => rotateImage(false)} sx={{...rotateButtonStyle, left: 16}}>
                        <RotateLeftIcon/>
                    </IconButton>
                    <IconButton onClick={() => rotateImage(true)} sx={{...rotateButtonStyle, right: 16}}>
                        <RotateRightIcon/>
                    </IconButton>
                    <Typography variant="subtitle1" sx={subtitleStyle}>
                        Image {index + 1} of {total}
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
});

const boxStyle = {
    position: 'relative',
    width: '90vw',
    maxWidth: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    overflow: 'hidden',
};

const imageStyle = {
    maxHeight: '80vh',
    maxWidth: '100%',
    objectFit: 'contain',
    display: 'block',
    margin: '0 auto'
};

const subtitleStyle = {
    mt: 2,
    textAlign: 'center',
    color: 'primary.contrastText',
    width: '100%',
    pt: 1,
    bgcolor: 'rgba(0, 0, 0, 0.5)'
};

const rotateButtonStyle = {
    position: 'absolute',
    top: '10%',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.9)'
    },
    transform: 'translateY(-50%)',
    zIndex: 1,
};

const swipeButtonStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1000
}

export default ImageViewer;
