const fetchWithAuth = async (navigate, url, options = {}) => {
    const response = await fetch(url, {...options, credentials: 'include'});
    if (response.status === 401 || response.status === 403) {
        navigate('/login');
        return;
    }
    return response;
}

export default fetchWithAuth;
