import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, IconButton, Box, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import LocationAutocomplete from "./LocationAutocomplete";
import apiConfig from "../apiConfig";
import urljoin from "url-join";
import fetchWithAuth from "./fetchWithAuth";
import ParticipantsAutocomplete from "./ParticipantsAutocomplete";

function UploadForm() {
    const navigate = useNavigate();

    const [trip, setTrip] = useState({
        name: '',
        startDate: new Date().toISOString().split('T')[0],
        participants: [],
        locations: [{ latitude: 0, longitude: 0, country: '', countryShort: '', city: '', images: [] }],
    });

    const [errors, setErrors] = useState({
        name: '',
        startDate: '',
        participants: '',
        locations: '',
    });

    const [formError, setFormError] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const handleTripChange = (event) => {
        setTrip({ ...trip, [event.target.name]: event.target.value });
    };

    const handleLocationChange = (index, field, value) => {
        const updatedLocations = [...trip.locations];
        updatedLocations[index] = { ...updatedLocations[index], [field]: value };
        setTrip({ ...trip, locations: updatedLocations });
    };

    const handleAddLocation = () => {
        setTrip({
            ...trip,
            locations: [
                ...trip.locations,
                { latitude: 0, longitude: 0, country: '', countryShort: '', city: '', images: [] },
            ],
        });
    };

    const handleRemoveLocation = (index) => {
        const updatedLocations = [...trip.locations];
        updatedLocations.splice(index, 1);
        setTrip({ ...trip, locations: updatedLocations });
    };

    const handleImageSelection = (e, index) => {
        const files = Array.from(e.target.files);
        if (files.length === 0) {
            handleLocationChange(index, 'images', []);
            return;
        }

        const readers = files.map((file) => {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64Image = reader.result.split(',')[1];
                    resolve(base64Image);
                };
                reader.readAsDataURL(file);
            });
        });

        Promise.all(readers).then((images) => {
            handleLocationChange(index, 'images', images);
        });
    };

    const handleLocationSelected = (index, selectedLocation) => {
        const updatedLocations = [...trip.locations];
        let newLocation;
        if (selectedLocation === null) {
            newLocation = {
                latitude: 0,
                longitude: 0,
                country: '',
                countryShort: '',
                city: '',
                images: updatedLocations[index].images,
            };
        } else {
            newLocation = {
                latitude: selectedLocation.geometry.location.lat(),
                longitude: selectedLocation.geometry.location.lng(),
                country: selectedLocation.address_components.find(c => c.types.includes('country'))?.long_name || '',
                countryShort: selectedLocation.address_components.find(c => c.types.includes('country'))?.short_name || '',
                city: selectedLocation.address_components.find(c => c.types.includes('locality'))?.long_name || '',
                images: updatedLocations[index].images,
            };
        }
        updatedLocations[index] = newLocation;
        setTrip({ ...trip, locations: updatedLocations });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setErrors({
            name: '',
            startDate: '',
            participants: '',
            locations: '',
        });
        setFormError('');

        let hasErrors = false;

        if (!trip.name) {
            setErrors(errors => ({ ...errors, name: 'Name is required.' }));
            hasErrors = true;
        }

        if (!trip.startDate) {
            setErrors(errors => ({ ...errors, startDate: 'StartDate is required.' }));
            hasErrors = true;
        }

        if (trip.participants.length === 0) {
            setErrors(errors => ({ ...errors, participants: 'At least one participant is required.' }));
            hasErrors = true;
        }

        if (trip.locations.length === 0) {
            setErrors(errors => ({ ...errors, locations: 'At least one location is required.' }));
            hasErrors = true;
        }

        if (trip.locations.some(location => !location.latitude || !location.longitude || location.images.length === 0)) {
            setErrors(errors => ({ ...errors, locations: 'Each location must be filled and have at least one image.' }));
            hasErrors = true;
        }

        if (hasErrors) {
            return;
        }

        const formData = {
            ...trip,
            account: "family",
        };

        const date = new Date(formData.startDate);
        formData.startDate = date.toISOString();

        try {
            setSubmitDisabled(true);
            const response = await fetchWithAuth(navigate, urljoin(apiConfig.baseUrl, 'CreateTrip'), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData)
            });
            if (!response.ok) {
                response.json().then(function (data) {
                    setFormError("Error: " + data.error);
                })
                setSubmitDisabled(false);
            } else {
                navigate('/map', { state: { latitude: trip.locations[0].latitude, longitude: trip.locations[0].longitude } });
            }
        } catch (error) {
            setFormError("Error: " + error);
            setSubmitDisabled(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
                <TextField
                    label="Name of the Trip"
                    name="name"
                    value={trip.name}
                    onChange={handleTripChange}
                    margin="normal"
                    style={{ marginRight: 16 }}
                    error={!!errors.name}
                    helperText={errors.name}
                />
                <TextField
                    label="startDate"
                    name="startDate"
                    type="date"
                    value={trip.startDate}
                    onChange={handleTripChange}
                    margin="normal"
                    style={{ marginRight: 16 }}
                    error={!!errors.startDate}
                    helperText={errors.startDate}
                />
                <ParticipantsAutocomplete
                    onChange={(event, newValue) => {
                        setTrip({ ...trip, participants: newValue });
                        setErrors(prev => ({ ...prev, participants: '' }));
                    }}
                    initialValues={trip.participants}
                />
            </Box>

            {trip.locations.map((location, index) => (
                <Box key={index} margin="normal" sx={locationBoxStyle}>
                    <Box sx={{ width: '60%', paddingRight: 2 }}>
                        <LocationAutocomplete
                            onLocationSelected={(selectedLocation) => handleLocationSelected(index, selectedLocation)}
                        />
                    </Box>
                    <Box sx={{ width: '20%', paddingRight: 2 }}>
                        {location.images.length > 0 ? (
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1">Selected {location.images.length} images</Typography>
                                <IconButton onClick={() => handleLocationChange(index, 'images', [])}>
                                    <RemoveCircleOutlineIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Button
                                component="label"
                                variant="contained"
                                startIcon={<CloudUploadIcon />}
                            >
                                Select Images
                                <input
                                    type="file"
                                    hidden
                                    multiple
                                    accept="image/png, image/jpeg"
                                    onChange={(e) => handleImageSelection(e, index)}
                                />
                            </Button>
                        )}
                    </Box>
                    <Box sx={{ width: '20%', display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton onClick={() => handleRemoveLocation(index)}>
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    </Box>
                </Box>
            ))}
            <Button onClick={handleAddLocation} startIcon={<AddCircleOutlineIcon />}>
                Add Location
            </Button>
            {errors.locations && (
                <Box margin="normal" color="error.main">
                    {errors.locations}
                </Box>
            )}
            <br />
            <Button disabled={submitDisabled} type="submit" variant="contained" color="primary">
                Submit
            </Button>
            {formError && (
                <Typography color="error" gutterBottom>
                    {formError}
                </Typography>
            )}
        </form>
    );
}

const locationBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 2,
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginTop: 2
};

export default UploadForm;
