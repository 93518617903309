import React from 'react';
import convertImageIDToURL from "../utils";
import apiConfig from "../apiConfig";
import fetchWithAuth from "./fetchWithAuth";
import urljoin from "url-join";
import {useNavigate} from "react-router-dom";
import { IconButton } from "@mui/material";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import DeleteIcon from '@mui/icons-material/Delete';


const ImageGallery = ({ images, imageSize, openImageFullScreen, location, onDeleteImage }) => {
    const navigate = useNavigate();

    const getImageHeight = (size) => {
        switch (size) {
            case 'small': return '100px';
            case 'medium': return '200px';
            case 'large': return '400px';
            default: return '200px';
        }
    };

    const setFavouriteImage = async (imageId, locationID) => {
        try {
            const response = await fetchWithAuth(navigate, urljoin(apiConfig.baseUrl, 'UpdateLocation'), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ locationID, imageId }),
            });

            if (!response.ok) {
                throw new Error('Failed to update favourite image');
            }
            navigate('/map', { state: { latitude: location.latitude, longitude: location.longitude } });
            window.location.reload();
        } catch (error) {
            console.error('Error setting favourite image:', error);
        }
    };

    return (
        <div style={galleryStyle}>
            {images.map((image, index) => (
                <div key={index} style={{ position: 'relative' }}>
                    <img
                        src={convertImageIDToURL(image, imageSize)}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = `${process.env.PUBLIC_URL}/smile.png`;
                        }}
                        onClick={() => openImageFullScreen(images, image)}
                        alt={`Gallery item ${index}`}
                        style={{...imageStyle, height: getImageHeight(imageSize)}}
                    />
                    {onDeleteImage && (
                        <IconButton
                            onClick={() => onDeleteImage(image)}
                            style={{ position: 'absolute', top: 0, right: 0 }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                    {location && location.city && (
                        <IconButton
                            onClick={() => setFavouriteImage(image, location.id)}
                            style={{...starStyle, color: image === location.favouriteImageID ? 'gold' : 'gray'}}
                        >
                            {image === location.favouriteImageID ? <StarIcon /> : <StarBorderIcon />}
                        </IconButton>
                    )}
                </div>
            ))}
        </div>
    );
};

const galleryStyle = {
    display: 'flex',
    overflowX: 'scroll',
    gap: '10px',
};

const imageStyle = {
    width: 'auto',
    objectFit: 'contain',
    borderRadius: '8px',
};

const starStyle = {
    position: 'absolute',
    top: '0px',
    right: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    borderRadius: '50%',
    padding: '5px'
};

export default ImageGallery;
