import React from 'react';
import { useLocation } from 'react-router-dom';
import '../App.css';

function PageLayout({ children }) {
    const pathname = useLocation().pathname;
    const isMapPage = pathname === '/map' || pathname === '/';

    return (
        <div className={isMapPage ? "" : "content-padding"}>
            {children}
        </div>
    );
}

export default PageLayout;
