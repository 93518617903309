import React, {forwardRef, useCallback, useLayoutEffect} from 'react';
import { Card, CardContent, Typography, IconButton } from '@mui/material';
import ImageGallery from "./ImageGallery";
import MapIcon from '@mui/icons-material/Map';
import {useNavigate} from "react-router-dom";
import TripsListHeader from "./TripsListHeader";


const TripsList = forwardRef(({ trips, locations, openImageFullScreen, imageSize, scrollToTripId }, ref) => {
    const navigate = useNavigate();

    const scrollToTrip = useCallback( async (tripId) => {
        if (ref[tripId] && ref[tripId].current) {
            ref[tripId].current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [ref]);

    useLayoutEffect(() => {
        if (scrollToTripId) {
            scrollToTrip(scrollToTripId);
        }
    }, [scrollToTrip, scrollToTripId]);

    return (
        trips.map((trip, index) => (
            <Card key={trip.id} ref={ref[trip.id]}  sx={{ marginBottom: 2, boxShadow: 3, borderRadius: 2, bgcolor: 'background.paper' }}>
                <TripsListHeader tripNameStyle={tripNameStyle} trip={trip}/>
                {trip.locations.map(locationRef => {
                    const location = locations.find(loc => loc.id === locationRef.locationID);
                    return (
                        <CardContent sx={{paddingTop: 0, paddingBottom: 0}} key={locationRef.locationID}>
                            <Typography variant="h6" gutterBottom sx={{ fontSize: "1.17em", color: 'text.secondary' }}>
                                {location ?
                                    `${location.city}, ${location.country}`
                                    : 'Unknown location'}
                                {location && (
                                    <IconButton onClick={() => navigate('/map', { state: { latitude: location.latitude, longitude: location.longitude, zoom: 11, groupBy: "cities" }})} sx={{ marginLeft: 1 }}>
                                        <MapIcon />
                                    </IconButton>
                                )}
                            </Typography>
                            <ImageGallery images={locationRef.imageIDs} imageSize={imageSize} openImageFullScreen={openImageFullScreen} />
                        </CardContent>
                    );
                })}
            </Card>
        ))
    );
});

const tripNameStyle = { fontWeight: 'bold', color: 'text.primary' };

export default TripsList;
