import React from "react";
import useImageGallery from "./useImageGallery";
import ImageModal from "./ImageModal";
import LocationTripsList from "./LocationTripsList";
import {Typography, Box} from "@mui/material";

function GalleryPopup({ location }) {
    const { openImageFullScreen, images, selectedImage, selectedImageIndex, handleNextImage, handlePreviousImage, handleCloseModal } = useImageGallery();

    return (
        <div>
            <Box sx={{ margin: 2 }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'text.primary', marginBottom: 2 }}>
                    {location.city ? location.city + ", " + location.country : location.country}
                </Typography>
                <LocationTripsList
                    trips={location.trips}
                    location={location}
                    openImageFullScreen={openImageFullScreen}
                />
            </Box>
            <ImageModal
                selectedImage={selectedImage}
                handleCloseModal={handleCloseModal}
                handleNextImage={handleNextImage}
                handlePreviousImage={handlePreviousImage}
                index={selectedImageIndex}
                total={images.length}
            />
        </div>
    );
}

export default GalleryPopup;