import React from 'react';
import {Autocomplete, TextField} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


export const participantsOptions = ["mom", "dad", "sasha", "tima", "masha", "alisa"];
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ParticipantsAutocomplete = ({ initialValues, onChange }) => {
    return (
        <Autocomplete
            multiple
            disableCloseOnSelect
            options={participantsOptions}
            value={initialValues}
            onChange={onChange}
            renderInput={(params) => <TextField {...params} label="Participants" />}
            style={{ flex: 1 }}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option}
                </li>
            )}
        />
    );
};

export default ParticipantsAutocomplete;