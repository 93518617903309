// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 600px) {
    .responsive-text {
        font-size: 0.6rem;
    }
    .responsive-chip {
        font-size: 0.6rem !important;
    }

    .responsive-icon {
        transform: scale(0.8);
        font-size: 1rem !important;
    }

    .MuiChip-labelSmall {
        padding-left: 3px !important;
        padding-right: 3px !important;
    }

    .MuiChip-sizeSmall {
        height: 12px !important;
    }

    .MuiIconButton-sizeSmall {
        padding: 0 !important;
    }

    .MuiChip-sizeMedium {
        height: 20px !important;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/TripsListHeader.css"],"names":[],"mappings":"AAAA;IACI;QACI,iBAAiB;IACrB;IACA;QACI,4BAA4B;IAChC;;IAEA;QACI,qBAAqB;QACrB,0BAA0B;IAC9B;;IAEA;QACI,4BAA4B;QAC5B,6BAA6B;IACjC;;IAEA;QACI,uBAAuB;IAC3B;;IAEA;QACI,qBAAqB;IACzB;;IAEA;QACI,uBAAuB;IAC3B;AACJ","sourcesContent":["@media screen and (max-width: 600px) {\n    .responsive-text {\n        font-size: 0.6rem;\n    }\n    .responsive-chip {\n        font-size: 0.6rem !important;\n    }\n\n    .responsive-icon {\n        transform: scale(0.8);\n        font-size: 1rem !important;\n    }\n\n    .MuiChip-labelSmall {\n        padding-left: 3px !important;\n        padding-right: 3px !important;\n    }\n\n    .MuiChip-sizeSmall {\n        height: 12px !important;\n    }\n\n    .MuiIconButton-sizeSmall {\n        padding: 0 !important;\n    }\n\n    .MuiChip-sizeMedium {\n        height: 20px !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
