import React, {useCallback, useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, TextField, IconButton, Box, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import apiConfig from '../apiConfig';
import urljoin from "url-join";
import fetchWithAuth from "./fetchWithAuth";
import ImageGallery from "./ImageGallery";
import ParticipantsAutocomplete from "./ParticipantsAutocomplete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import LocationAutocomplete from "./LocationAutocomplete";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

function EditTrip() {
    const navigate = useNavigate();

    const { tripId } = useParams();
    const [trip, setTrip] = useState(null);
    const [locations, setLocations] = useState([]);
    const [addingLocation, setAddingLocation] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [err, setErr] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteInfo, setDeleteInfo] = useState({ type: '', locationID: -1, imageID: -1 });

    const fetchTrip = useCallback(async (id) => {
        try {
            const response = await fetchWithAuth(navigate, urljoin(apiConfig.baseUrl, 'GetTrip'), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id })
            });
            const data = await response.json();
            setTrip(data.trip);

            const locationsResponse = await fetchWithAuth(navigate, urljoin(apiConfig.baseUrl, 'GetLocations'));
            const locationsData = await locationsResponse.json();
            setLocations(locationsData.locations);

            setIsLoading(false);
        } catch (error) {
            setErr(error.message);
            setIsLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        fetchTrip(tripId);
    }, [fetchTrip, tripId, navigate]);

    const handleUpdateMetadata = async () => {
        try {
            const date = new Date(trip.startDate);
            var startDate = date.toISOString();
            const response = await fetchWithAuth(navigate, urljoin(apiConfig.baseUrl, 'UpdateTripMeta'), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: trip.id, account: trip.account, name: trip.name, startDate: startDate, participants: trip.participants })
            });
            if (!response.ok) {
                const responseData = await response.json();
                const errorMessage = responseData.error || 'Failed to update trip metadata.';
                throw new Error(errorMessage);
            }
        } catch (error) {
            setErr(error.message);
            console.error(error);
        }
    };

    const getImagesFromEvent = async (e) => {
        const files = Array.from(e.target.files);
        if (files.length === 0) {
            return;
        }

        const readers = files.map((file) => {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64Image = reader.result.split(',')[1];
                    resolve(base64Image);
                };
                reader.readAsDataURL(file);
            });
        });

        return Promise.all(readers)
    };

    const handleAddImages = async (locationID, e) => {
        await getImagesFromEvent(e).then(async (images) => {
            try {
                const response = await fetchWithAuth(navigate, urljoin(apiConfig.baseUrl, 'AddImages'), {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ tripID: trip.id, locationID, images })
                });
                if (!response.ok) {
                    const responseData = await response.json();
                    const errorMessage = responseData.error || 'Failed to add images.';
                    throw new Error(errorMessage);
                }
                window.location.reload();
            } catch (error) {
                setErr(error.message);
                console.error(error);
            }
        });
    };

    const handleSubmitLocation = async () => {
        try {
            const response = await fetchWithAuth(navigate, urljoin(apiConfig.baseUrl, 'AddLocation'), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ tripID: trip.id, location: addingLocation })
            });
            if (!response.ok) {
                const responseData = await response.json();
                const errorMessage = responseData.error || 'Failed to add location.';
                throw new Error(errorMessage);
            }
            window.location.reload();
        } catch (error) {
            setErr(error.message);
            console.error(error);
        }
    };

    const handlePrepareLocation = () => {
        setAddingLocation({ latitude: 0, longitude: 0, country: '', countryShort: '', city: '', images: [] });
    }

    const handleLocationSelected = (loc) => {
        if (loc === null) {
            setAddingLocation({latitude: 0, longitude: 0, country: '', countryShort: '', city: '', images: addingLocation.images});
            return;
        }
        setAddingLocation({
            latitude: loc.geometry.location.lat(),
            longitude: loc.geometry.location.lng(),
            country: loc.address_components.find(c => c.types.includes('country'))?.long_name || '',
            countryShort: loc.address_components.find(c => c.types.includes('country'))?.short_name || '',
            city: loc.address_components.find(c => c.types.includes('locality'))?.long_name || '',
            images: addingLocation.images
        });
    };

    const handleLocationImageSelection = async (e) => {
        await getImagesFromEvent(e).then(async (images) => {setAddingLocation({...addingLocation, images})});
    };

    const deleteTrip = async () => {
        try {
            const response = await fetchWithAuth(navigate, urljoin(apiConfig.baseUrl, 'DeleteTrip'), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: trip.id }),
            });
            if (!response.ok) {
                const responseData = await response.json();
                const errorMessage = responseData.error || 'Failed to delete the trip.';
                throw new Error(errorMessage);
            }
            navigate('/gallery');
        } catch (error) {
            setErr(error.message);
            console.error(error);
        }
    };

    const deleteLocation = async (locationID) => {
        try {
            const response = await fetchWithAuth(navigate, urljoin(apiConfig.baseUrl, 'DeleteTripLocation'), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ tripID: trip.id, locationID }),
            });
            if (!response.ok) {
                const responseData = await response.json();
                const errorMessage = responseData.error || 'Failed to delete the location.';
                throw new Error(errorMessage);
            }
            window.location.reload();
        } catch (error) {
            setErr(error.message);
            console.error(error);
        }
    };

    const deleteImage = async (locationID, imageID) => {
        try {
            const response =await fetchWithAuth(navigate, urljoin(apiConfig.baseUrl, 'DeleteImage'), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ tripID: trip.id, locationID, imageID }),
            });
            if (!response.ok) {
                const responseData = await response.json();
                const errorMessage = responseData.error || 'Failed to delete the image.';
                throw new Error(errorMessage);
            }
            window.location.reload();
        } catch (error) {
            setErr(error.message);
            console.error(error);
        }
    };

    const handleDelete = (type, locationID, imageID) => {
        setOpenDialog(true);
        setDeleteInfo({ type, locationID, imageID });
    };

    const confirmDelete = async () => {
        if (deleteInfo.type === 'trip') {
            await deleteTrip();
        } else if (deleteInfo.type === 'location') {
            await deleteLocation(deleteInfo.locationID);
        } else if (deleteInfo.type === 'image') {
            await deleteImage(deleteInfo.locationID, deleteInfo.imageID);
        }
        setOpenDialog(false);
    };

    if (isLoading) return <p>Loading...</p>;
    if (!trip) return <p>Error loading the trip: {err}</p>;

    return (
        <div>
            <form onSubmit={handleUpdateMetadata}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <TextField
                        label="Name of the Trip"
                        value={trip.name}
                        onChange={(e) => setTrip({ ...trip, name: e.target.value })}
                        margin="normal"
                        style={{ marginRight: 16 }}
                    />
                    <TextField
                        label="Start Date"
                        type="date"
                        value={trip.startDate.substring(0, 10)}
                        onChange={(e) => setTrip({ ...trip, startDate: e.target.value })}
                        margin="normal"
                        style={{ marginRight: 16 }}
                    />
                    <ParticipantsAutocomplete
                        onChange={(event, newValue) => setTrip({ ...trip, participants: newValue })}
                        initialValues={trip.participants}
                    />
                </Box>
                <Box mb={2}>
                    <Button type="submit" variant="contained" color="primary">Update Metadata</Button>
                </Box>
            </form>

            {trip.locations.map(locationRef => {
                const location = locations.find(loc => loc.id === locationRef.locationID);
                return (
                    <Box key={location.id} margin="normal">
                        <Typography>
                            {location.city}, {location.country}
                            <IconButton
                                component="label"
                                variant="contained"
                                size="small"
                            >
                                <AddAPhotoIcon />
                                <input
                                    type="file"
                                    hidden
                                    multiple
                                    accept="image/png, image/jpeg"
                                    onChange={(e) => handleAddImages(location.id, e)}
                                />
                            </IconButton>
                            <IconButton onClick={() => handleDelete('location', location.id)}>
                                <DeleteIcon/>
                            </IconButton>
                        </Typography>

                        <ImageGallery
                            images={locationRef.imageIDs}
                            imageSize="small"
                            openImageFullScreen={(images, image) => {}}
                            onDeleteImage={(image) => handleDelete('image', location.id, image)}
                        />
                    </Box>
                )
                }
            )}

            {addingLocation === null && (
                <Button onClick={handlePrepareLocation} startIcon={<AddCircleOutlineIcon />}>
                    Add Location
                </Button>
            )}
            {addingLocation && (
                <Box sx={locationBoxStyle}>
                    <Box sx={{ width: '60%', paddingRight: 2 }}>
                        <LocationAutocomplete
                            onLocationSelected={(selectedLocation) => handleLocationSelected(selectedLocation)}
                        />
                    </Box>
                    <Box sx={{ width: '20%', paddingRight: 2 }}>
                        {addingLocation.images.length > 0 ? (
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1">Selected {addingLocation.images.length} images</Typography>
                                <IconButton onClick={() => setAddingLocation({ ...addingLocation, 'images': [] })}>
                                    <RemoveCircleOutlineIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Button
                                component="label"
                                variant="contained"
                                startIcon={<CloudUploadIcon />}
                            >
                                Select Images
                                <input
                                    type="file"
                                    hidden
                                    multiple
                                    accept="image/png, image/jpeg"
                                    onChange={(e) => handleLocationImageSelection(e)}
                                />
                            </Button>
                        )}
                    </Box>
                    <Box sx={{ width: '10%' }}>
                        <Button onClick={handleSubmitLocation} variant="contained" color="primary">
                            Submit
                        </Button>
                    </Box>
                    <Box sx={{ width: '10%', display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton onClick={() => setAddingLocation(null)}>
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    </Box>
                </Box>
            )}
            <br/>
            <Button variant="contained" color="error" onClick={() => handleDelete('trip')}>
                Delete Trip
            </Button>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            >
                <DialogTitle>{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you really want to delete this {deleteInfo.type}? This process cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={confirmDelete} color="primary">
                        Yes
                    </Button>
                    <Button onClick={() => setOpenDialog(false)} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            {err && (
                <Typography color="error" gutterBottom>
                    Error: {err}
                </Typography>
            )}
        </div>
    );
}

const locationBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 2,
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginTop: 2,
};


export default EditTrip;
