import React from 'react';
import { CardContent, Typography, IconButton, Box, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import {participantsColorMap} from "../globalState";
import {useNavigate} from "react-router-dom";
import './TripsListHeader.css'


function TripsListHeader({ trip, showScrollButton, tripNameStyle }) {
    const navigate = useNavigate();

    return (
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 0 }}>
            <Box sx={{display: 'flex', width: '100%', alignItems: 'center'}}>
                <Box sx={{ width: '40%' }}>
                    <Typography variant="h5" component="div" sx={tripNameStyle}>
                        <div className="responsive-text">
                            {trip.city ? trip.city + ", " + trip.name : trip.name}
                            {showScrollButton && (
                                <IconButton
                                    size="small"
                                    onClick={() => navigate('/gallery', { state: { scrollToTripId: trip.id } })}
                                >
                                    <PhotoLibraryIcon className="responsive-icon"/>
                                </IconButton>
                            )}
                        </div>
                    </Typography>
                </Box>
                <Box sx={{
                    width: '30%',
                    paddingRight: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    {trip.participants
                        .map(participant => ({ ...participantsColorMap[participant], key: participant }))
                        .sort((a, b) => a.order - b.order)
                        .map(participant => (
                            <Chip
                                label={participant.label}
                                color={participant.color}
                                sx={{ color: '#fff', marginLeft: 0.5 }}
                                size="small"
                                key={participant.key}
                                className="responsive-chip"
                            />
                        ))
                    }
                </Box>
                <Box sx={{ width: '30%', paddingRight: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <IconButton size="small" onClick={() => navigate(`/editTrip/${trip.id}`)} sx={{ marginLeft: 1 }}>
                        <EditIcon className="responsive-icon" />
                    </IconButton>
                    <Chip
                        label={trip.startDate.split('T')[0].split('-')[0]}
                        icon={<CalendarMonthIcon className="responsive-icon" />}
                        sx={{ fontWeight: 'bold' }}
                    />
                </Box>
            </Box>
        </CardContent>
    );
}

export default TripsListHeader;
