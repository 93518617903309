import React from 'react';
import { useLocation } from 'react-router-dom';

function PageLayout({ children }) {
    const isLoginPage = useLocation().pathname === '/login';

    return (
        <div hidden={isLoginPage}>
            {children}
        </div>
    );
}

export default PageLayout;
