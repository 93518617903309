import { useState, useCallback, useEffect } from 'react';

const useImageGallery = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [images, setImages] = useState([]);
    const [imageSize, setImageSize] = useState(localStorage.getItem('galleryImageSize') || 'medium');

    const openImageFullScreen = useCallback((imageIds, imageId) => {
        setImages(imageIds);
        setSelectedImage(imageId);
        setSelectedImageIndex(imageIds.indexOf(imageId));
    }, []);

    const handleNextImage = useCallback(() => {
        setSelectedImageIndex(prevIndex => (prevIndex + 1) % images.length);
        setSelectedImage(images[(selectedImageIndex + 1) % images.length]);
    }, [images, selectedImageIndex]);

    const handlePreviousImage = useCallback(() => {
        setSelectedImageIndex(prevIndex => (prevIndex - 1 + images.length) % images.length);
        setSelectedImage(images[(selectedImageIndex - 1 + images.length) % images.length]);
    }, [images, selectedImageIndex]);

    const handleCloseModal = useCallback(() => {
        setSelectedImage(null);
    }, []);

    const handleSizeChange = useCallback((size) => {
        localStorage.setItem('galleryImageSize', size);
        setImageSize(size);
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowRight') {
                handleNextImage();
            } else if (event.key === 'ArrowLeft') {
                handlePreviousImage();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleNextImage, handlePreviousImage]);

    return {
        openImageFullScreen,
        images,
        selectedImage,
        selectedImageIndex,
        handleNextImage,
        handlePreviousImage,
        handleCloseModal,
        imageSize,
        handleSizeChange
    };
};

export default useImageGallery;
