import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Typography } from '@mui/material';
import urljoin from "url-join";
import apiConfig from "../apiConfig";

const Login = () => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const response = await fetch( urljoin(apiConfig.baseUrl, 'Login'), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ account: "family", password }),
                credentials: 'include',
            });
            if (response.status === 200) {
                navigate('/map');
            } else {
                const errorData = await response.json();
                setError(errorData.message || 'Login failed');
            }
        } catch (error) {
            setError('Failed to login: unexpected error');
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                maxWidth: '300px',
                margin: 'auto',
                '& > :not(style)': { m: 1 },
            }}
        >
            <Typography variant="h5" component="h1" gutterBottom>
                Welcome!
            </Typography>
            <TextField
                label="Password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                variant="outlined"
                fullWidth
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleLogin}
                sx={{ mt: 2 }}
            >
                Sign In
            </Button>
            {error && (
                <Typography color="error" sx={{ mt: 2 }}>
                    {error}
                </Typography>
            )}
        </Box>
    );
};

export default Login;
