import React from 'react';
import { Card, CardContent } from '@mui/material';
import ImageGallery from "./ImageGallery";
import TripsListHeader from "./TripsListHeader";

function LocationTripsList({ location, trips, openImageFullScreen }) {
    return (
        <div>
            {trips.map(trip => (
                <Card key={trip.id + trip.city} sx={{ marginBottom: 2, boxShadow: 3, borderRadius: 2, bgcolor: 'background.paper' }}>
                    <TripsListHeader trip={trip} tripNameStyle={tripNameStyle} showScrollButton={true}/>
                    <CardContent sx={{paddingTop: 0, paddingBottom: 0}}>
                        <ImageGallery images={trip.imageIDs} imageSize="medium" location={location} openImageFullScreen={openImageFullScreen} />
                    </CardContent>
                </Card>
            ))}
        </div>
    );
}

const tripNameStyle = { fontSize: "1.17em", color: 'text.secondary' };

export default LocationTripsList;
