import apiConfig from "./apiConfig";
import { getLastUpdateTimestamp } from './globalState';

function convertImageIDToURL(imageID, size) {
    if (size === undefined) {
        return `https://storage.googleapis.com/travel-map/${apiConfig.gcsDirname}/family/${imageID}?ts=${getLastUpdateTimestamp()}`;
    }
    return `https://storage.googleapis.com/travel-map/${apiConfig.gcsDirname}/family/${size}/${imageID}?ts=${getLastUpdateTimestamp()}`;
}

export default convertImageIDToURL;
